<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center mb-4">
          <div v-if="!usuario.foto_perfil" class="bg-warning rounded-circle me-3" style="width: 80px; height: 80px;"></div>
          <img v-else :src="usuario.foto_perfil" class="rounded-circle me-3" style="width: 80px; height: 80px; object-fit: cover;">
          <div>
            <h4 class="mb-0">{{ usuario.nombres + ' ' + usuario.apellidos }}</h4>
            <p class="text-muted mb-0">{{ usuario.profesion }}</p>
            <p class="text-muted mb-0">{{ usuario.cargo }}</p>
          </div>
        </div>

        <form @submit.prevent="actualizarPerfil">
          <div class="row g-3">
            <div class="col-md-4">
              <input 
                type="text" 
                class="form-control" 
                placeholder="Nombres"
                v-model="usuario.nombres">
            </div>
            <div class="col-md-4">
              <input 
                type="text" 
                class="form-control" 
                placeholder="Apellidos"
                v-model="usuario.apellidos">
            </div>
            <div class="col-md-4">
              <input 
                type="email" 
                class="form-control" 
                placeholder="Email"
                v-model="usuario.correo">
            </div>
            <div class="col-md-4">
              <input 
                type="tel" 
                class="form-control" 
                placeholder="Celular"
                v-model="usuario.telefono">
            </div>
            <div class="col-md-4">
              <input 
                type="text" 
                class="form-control" 
                placeholder="Ciudad"
                v-model="usuario.direccion">
            </div>
            <div class="col-md-4">
              <input 
                type="text" 
                class="form-control" 
                placeholder="Profesión"
                v-model="usuario.profesion">
            </div>
            <div class="col-md-4">
              <input 
                type="text" 
                class="form-control" 
                placeholder="DNI"
                v-model="usuario.dni">
            </div>
            <div class="col-md-4">
              <input 
                type="date" 
                class="form-control" 
                placeholder="Fecha de Nacimiento"
                v-model="usuario.fechaNacimiento">
            </div>
            <div class="col-md-4">
              <select 
                class="form-select" 
                v-model="usuario.sexo">
                <option value="" disabled>Sexo</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </div>
            <div class="col-md-4">
              <input 
                type="text" 
                class="form-control" 
                placeholder="Código de Alumno"
                v-model="usuario.id_alumno">
            </div>
            <div class="col-md-4">
              <input 
                type="password" 
                class="form-control" 
                placeholder="Contraseña"
                v-model="usuario.contrasena">
            </div>
            <div class="col-md-4">
              <input 
                type="password" 
                class="form-control" 
                placeholder="Repetir Contraseña"
                v-model="usuario.repetirContrasena">
            </div>
          </div>

          <div class="mt-4">
            <button type="submit" class="btn btn-danger me-2">
              Actualizar
            </button>
            <button type="button" class="btn btn-link text-dark" @click="cancelarEdicion">
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioService from '@/services/UsuarioService';

export default {
  name: 'ProfileForm',
  data() {
    return {
      usuario: {
        nombres: '',
        apellidos: '',
        email: '',
        celular: '',
        ciudad: '',
        profesion: '',
        dni: '',
        fechaNacimiento: '',
        codigoAlumno: '',
        sexo: '',
        contrasena: '',
        repetirContrasena: '',
        foto_perfil: null,
        cargo: ''
      },
      user: JSON.parse(localStorage.getItem("user")),
    }
  },
  created() {
    this.obtenerUsuario();
  },
  methods: {
    async obtenerUsuario() {
      try {
        const response = await UsuarioService.getPerfilUsuario(this.user.id);
        this.usuario = response.data.data;
      } catch (error) {
        console.error("Error al obtener el perfil del usuario:", error);
      }
    },
    cancelarEdicion() {
      this.obtenerUsuario();
    },
    async actualizarPerfil() {
      try {
        await UsuarioService.actualizarUsuario(this.user.id, this.usuario);
        this.$swal("Perfil actualizado con éxito", "", "success");
      } catch (error) {
        console.error("Error al actualizar el perfil:", error);
        this.$swal("Error al actualizar el perfil", "", "error");
      }
    },
  }
}
</script>

<style scoped>
.card {
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
}

.form-control, .form-select {
  padding: 0.75rem 1rem;
  border-radius: 6px;
}

.btn-danger {
  background-color: #ff4858;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 6px;
}

.btn-link {
  text-decoration: none;
}
</style>