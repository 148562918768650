<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center w-100"
      style="height: 80vh; width: 100%"
      v-if="!haComenzado"
    >
      <div>
        <h1 class="text-danger fs-6">Examen</h1>
        <p>Curso: {{ nombre_curso }}</p>
        <h3>{{ nombre_modulo }}</h3>
        <div class="card p-4 w-100">
          <h5>Instrucciones</h5>
          <ul>
            <li>
              Tienes {{ examen.intentos }} intentos para resolver el examen
            </li>
            <li>El examen consta de {{ cantidad_preguntas }} preguntas</li>
            <li>
              Tienes {{ examen.duracion }} minutos para resolver el examen
            </li>
            <div
              class="btn-group mt-4"
              role="group"
              aria-label="botones examen"
            >
              <button
                type="button "
                class="btn btn-danger"
                @click="comenzarExamen"
              >
                Empezar
              </button>
              <button
                type="button"
                class="btn btn-light"
                @click="$router.go(-1)"
              >
                Cancelar
              </button>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center w-100"
      style="height: 80vh; width: 100%"
      v-if="haComenzado && preguntaActual"
    >
    
      <div class="card p-4 w-50">
        <p>{{ preguntaActual.pregunta }}</p>
        <li
          v-for="opcion in preguntaActual.opciones"
          :key="opcion.id"
          style="list-style: none"
        >
          <input
            type="radio"
            :value="opcion.id"
            v-model="respuestas[preguntaActual.id]"
          />
          {{ opcion.opcion }}
        </li>
        <div class="mt-4 g-2">
          <button
            class="m-2 btn btn-secondary"
            v-if="preguntaIndice > 0"
            @click="anteriorPregunta"
          >
            Anterior
          </button>
          <button
            class="m-2 btn btn-danger"
            v-if="preguntaIndice < preguntas.length - 1"
            @click="siguientePregunta"
          >
            Siguiente
          </button>
          <button
            class="m-2 btn btn-success"
            v-if="preguntaIndice == preguntas.length - 1"
            @click="enviarExamen"
          >
            Enviar Examen
          </button>
        </div>
      </div>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>
<script>
import CursoService from "../../services/CursoService";
import ExamenService from "../../services/ExamenService";
import Preloader from "../components/Preloader.vue";

export default {
  data() {
    return {
      haComenzado: false,
      preguntaIndice: 0,
      cargando: false,
      idmodulo: this.$route.params.idmodulo,
      nombre_curso: "",
      nombre_modulo:"",
      cantidad_preguntas: 0,
      examen: {
        intendos: 0,
        duracion: 0,
      },
      preguntas: [],
      respuestas: [],
      notaFinal: 0,
    };
  },
  created() {
    this.fetchExamen();
  },
  computed: {
    preguntaActual() {
      return this.preguntas[this.preguntaIndice];
    },
  },
  components: {
    Preloader,
  },
  methods: {
    async fetchExamen() {
      this.fecthNombreCurso();
      try {
        this.cargando = true;
        const response = await ExamenService.getExamenPorModulo(this.idmodulo);
        this.examen = response.data.data;
        this.fecthPreguntas(this.examen.id);
      } catch (error) {
        console.error("Error fetching Examen:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error el examen.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fecthNombreCurso() {
      try {
        this.cargando = true;
        const response = await CursoService.getNombreCurso(this.idmodulo);
        this.nombre_curso = response.data.nombre_curso;
        this.nombre_modulo = response.data.nombre_modulo;
        
      } catch (error) {
        console.error("Error fetching curso:", error);
      } finally {
        this.cargando = false;
      }
    },
    async fecthPreguntas(id) {
    
      try {
        this.cargando = true;
        const response = await ExamenService.getPreguntasPorEvaluacion(id);
        this.preguntas = response.data.data;
        this.cantidad_preguntas=this.preguntas.length;
      } catch (error) {
        console.error("Error fetching curso:", error);
      } finally {
        this.cargando = false;
      }
    },
    async comenzarExamen() {
      this.haComenzado = true;
     
    },
    siguientePregunta() {
      if (this.preguntaIndice < this.preguntas.length - 1) {
        this.preguntaIndice++;
      }
    },
    anteriorPregunta() {
      if (this.preguntaIndice > 0) {
        this.preguntaIndice--;
      }
    },
    enviarExamen() {
      let nota = 0;
      this.preguntas.forEach((pregunta, index) => {
        const respuestaSeleccionada = this.respuestas[index + 1];
        const opcionSeleccionada = pregunta.opciones.find(
          (opcion) => opcion.id === respuestaSeleccionada
        );
        if (opcionSeleccionada && opcionSeleccionada.condicion === 1) {
          nota += pregunta.valor;
        }
      });
      this.notaFinal = nota;
      this.$swal({
        icon: "success",
        title: "Examen Completado",
        text: `Tu nota final es: ${this.notaFinal}`,
      });
    },
  },
};
</script>
<style></style>
