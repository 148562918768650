<template >
  <div class="container mt-5">
    <div class="row">
      
      {{ curso }}

      <div class="col-md-8">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Cursos</a></li>
            <li class="breadcrumb-item">
              <a href="#">Programación y tecnología</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Desarrollo de software
            </li>
          </ol>
        </nav>

        <h1 class="mb-3">Curso: {{ curso.nombre }}</h1>
        <p class="lead">{{ curso.descripcion }}</p>

        <h4 class="mt-4">¿Qué aprenderás?</h4>
        <ul class="list-unstyled">
          <li>
            <i class="bi bi-check-circle-fill text-success"></i> Comprender los
            fundamentos de programación.
          </li>
          <li>
            <i class="bi bi-check-circle-fill text-success"></i> Comprender la
            lógica detrás de cualquier lenguaje.
          </li>
          <li>
            <i class="bi bi-check-circle-fill text-success"></i> Resolver
            ejercicios de programación.
          </li>
        </ul>

        <div class="d-flex align-items-center my-4">
          <span class="me-3 text-warning"
            ><i class="bi bi-star-fill"></i> 4.8 de calificación</span
          >
          <span class="me-3"><i class="bi bi-calendar"></i> Jun 2023</span>
          <span class="me-3"><i class="bi bi-clock"></i> 6 horas</span>
          <span class="me-3"><i class="bi bi-bar-chart"></i> Básico</span>
        </div>
      </div>

      <div class="col-md-4">
        <img
          src="https://via.placeholder.com/400x300"
          class="img-fluid rounded"
          alt="Imagen del curso"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8">
      <div class="card mb-4">
        <div v-for="modulo in curso.modulos" :key="modulo.id">
          <div class="card-header">
            <h2>{{ modulo.nombre }}</h2>
          </div>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              v-for="leccion in modulo.lecciones"
              :key="leccion.id"
            >
              <span @click="irLeccion(modulo.id, leccion.id)">ir</span>
              {{ leccion.titulo }}
              <span class="badge bg-primary rounded-pill">08:49</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <h3>Profesores del curso</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="d-flex align-items-center">
              <img
                src="path_to_alvaro_image"
                class="rounded-circle me-3"
                width="50"
                height="50"
                alt="Alvaro Felipe Chávez"
              />
              <div>
                <h5 class="mb-0">Alvaro Felipe Chávez</h5>
                <small class="text-muted"
                  >Crack, ídolo, máquina, artista, inigualable, fiera y CEO de
                  EDteam.</small
                >
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex align-items-center">
              <img
                src="path_to_beto_image"
                class="rounded-circle me-3"
                width="50"
                height="50"
                alt="Beto Quiroga"
              />
              <div>
                <h5 class="mb-0">Beto Quiroga</h5>
                <small class="text-muted">COO en EDteam</small>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex align-items-center">
              <img
                src="path_to_alexys_image"
                class="rounded-circle me-3"
                width="50"
                height="50"
                alt="Alexys Lozada"
              />
              <div>
                <h5 class="mb-0">Alexys Lozada</h5>
                <small class="text-muted">@alexyslozada</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CursoService from "../../services/CursoService";

export default {
  name: "Cursos",
  data() {
    return {
      curso: {},
      idcurso: this.$route.params.idcurso,
      cargando: false,
    };
  },

  created() {
    this.fetchCurso();
  },
  methods: {
    async fetchCurso() {
      try {
        this.cargando = true;
        const response = await CursoService.getCursoDetallado(this.idcurso);
        this.curso = response.data.data;
        console.log(this.curso);
      } catch (error) {
        console.error("Error fetching Cursos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Cursos.",
        });
      } finally {
        this.cargando = false;
      }
    },
    irLeccion(idmodulo, idleccion) {
      this.$router.push({
        name: "Leccion",
        params: {
          idleccion: idleccion,
          idmodulo: idmodulo,
          idcurso: this.idcurso,
        },
      });
    },
  },
};
</script>
