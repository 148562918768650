<template>
  <div class="certificates-container">
    <h2 class="mb-4">Mis Certificados</h2>

    <div class="table-responsive">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col">Lista de Certificados</th>
            <th scope="col" class="text-center">Calificación</th>
            <th scope="col" class="text-center">Descargar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(modulo, index) in modulos" :key="modulo.id">
            <td>
              <div class="d-flex align-items-center">
                <div
                  class="certificate-icon me-3"
                  :class="getCertificateIconClass(index)"
                ></div>
                <p class="mb-0">{{ modulo.nombre }}</p>
              </div>
            </td>
            <td class="text-center">
              {{ obtenerCalificacion(modulo.id) }}
            </td>
            <td class="text-center">              
              <span v-if="obtenerCertificacion(modulo.id)!='Sin certificado'">
                <button
                  @click="descargarCertificado(modulo.id)"
                  class="btn btn-download"
                >
                  <i class="bi bi-download"></i>
                </button>
              </span>
              <span v-else>Sin Certificado</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import CalificacionService from "../../services/CalificacionService";
import CertificadoService from "../../services/CertificadoService";
import ModuloService from "../../services/ModuloService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "CertificadosModulos",
  components: {
    Preloader,
  },
  data() {
    return {
      modulos: [],
      id_curso: this.$route.params.id_curso,
      calificaciones: {},
      certificados: {},
      cursoNombre: "",
      cargando: false,
      user: JSON.parse(localStorage.getItem("user")),
      cert: "",
    };
  },
  created() {
    this.fetchModulos(this.id_curso);
  },
  methods: {
    async llamarCalificacion(id_modulo) {
      try {
        const response =
          await CalificacionService.getCalificacionporModulodeUsuario(
            id_modulo,
            this.user.id
          );

        this.calificaciones[id_modulo] = response.data.data[0].calificacion;
      } catch (error) {
        console.error("Error fetching calificación", error);
      }
    },
    async llamarCertificado(id_modulo) {
      try {
        const response =
          await CertificadoService.getCertificadoporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.certificados[id_modulo] = response.data.data[0].id;
        //this.certificados = response.data.data[0];
        this.cert = response.data.data[0];
        console.log(this.cert);
      } catch (error) {
        console.error("Error fetching certificado", error);
      }
    },
    obtenerCalificacion(id_modulo) {
      return this.calificaciones[id_modulo] || "Sin calificación";
    },
    obtenerCertificacion(id_modulo) {
      this.llamarCertificado(id_modulo);
      return this.certificados[id_modulo] || "Sin certificado";
    },
    async fetchModulos(id_curso) {
      try {
        this.cargando = true;
        const response = await ModuloService.getModulosporCurso(id_curso);
        this.modulos = response.data.data;

        for (const modulo of this.modulos) {
          await this.llamarCalificacion(modulo.id);
          // await this.obtenerCertificacion(modulo.id);
        }
      } catch (error) {
        console.error("Error fetching Modulos", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los módulos.",
        });
      } finally {
        this.cargando = false;
      }
    },
    getCertificateIconClass(index) {
      return index === 1 ? "bg-primary" : "bg-warning";
    },
    getDownloadButtonClass(index) {
      return index === 1 ? "btn-light" : "btn-info";
    },
    descargarCertificado(id_modulo) {
      this.llamarCertificado(id_modulo);
      console.log(this.llamarCertificado);
    },
  },
};
</script>

<style scoped>
.certificates-container {
  padding: 20px;
  background-color: #f8f9fa;
}

.table th {
  font-weight: bold;
  color: #6c757d;
}

.certificate-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.btn-download {
  padding: 6px 10px;
  border-radius: 6px;
}

.bg-primary {
  background-color: #ff9800 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.btn-info {
  background-color: #17a2b8;
  color: white;
}

.btn-light {
  background-color: white;
  color: #3b348c;
}
</style>
