import apiClient from '../axios';

export default {
    getCursos() {
        return apiClient.get('/cursos');
    },
    getCursosMatriculadosPorUsuario(id) {
        return apiClient.get(`/cursos/usuario/${id}`);
    },
    getCurso(id) {
        return apiClient.get(`/cursos/${id}`);
    },

    getCursoDetallado(id) {
        return apiClient.get(`/cursos/detallado/${id}`);
    },
    getNombreCurso(idmodulo) {
        return apiClient.get(`/cursos/nombre/modulo/${idmodulo}`);
    }

    /*getCertificadosUsuario(id) {
        return apiClient.get(`/certificados/usuario/${id}`);
    }*/
};