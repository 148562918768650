<template>
    <div>
        <div id="customCarousel" class="carousel slide" data-bs-ride="carousel" style="background: linear-gradient(90deg, #070E27, #194994);">
            <!-- Indicadores (Puntos) -->
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#customCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#customCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#customCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
          
            <div class="carousel-inner text-white">
              <!-- Slide 1 -->
              <div class="carousel-item active">
                <div class="d-flex align-items-center" style="min-height: 400px;">
                  <div class="container d-flex">
                    <div class="w-50 d-flex flex-column justify-content-center">
                      <h2 class="mb-3 text-white">Educación Online de Calidad</h2>
                      <p class="mb-3">Accede a +650 cursos y más de 50 especialidades.</p>
                      <a href="#" class="btn btn-primary">Acceso Total Aquí</a>
                    </div>
                    <div class="w-50">
                      <img src="@/assets/images/2729.png" class="d-block w-100" alt="Image 1">
                    </div>
                  </div>
                </div>
              </div>
          
              <!-- Slide 2 -->
              <div class="carousel-item">
                <div class="d-flex align-items-center" style="min-height: 400px;">
                  <div class="container d-flex">
                    <div class="w-50 d-flex flex-column justify-content-center">
                      <h2 class="mb-3 text-white">Educación Online de Calidad</h2>
                      <p class="mb-3">Accede a +650 cursos y más de 50 especialidades.</p>
                      <a href="#" class="btn btn-primary">Acceso Total Aquí</a>
                    </div>
                    <div class="w-50">
                      <img src="@/assets/images/2729.png" class="d-block w-100" alt="Image 2">
                    </div>
                  </div>
                </div>
              </div>
          
              <!-- Slide 3 -->
              <div class="carousel-item">
                <div class="d-flex align-items-center" style="min-height: 400px;">
                  <div class="container d-flex">
                    <div class="w-50 d-flex flex-column justify-content-center">
                      <h2 class="mb-3 text-white">Educación Online de Calidad</h2>
                      <p class="mb-3">Accede a +650 cursos y más de 50 especialidades.</p>
                      <a href="#" class="btn btn-primary">Acceso Total Aquí</a>
                    </div>
                    <div class="w-50">
                      <img src="@/assets/images/2729.png" class="d-block w-100" alt="Image 3">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

        <div class="mt-4 ">
            <div class="row ">
                <div class="col-md-12">
                    <h5 class="text-white">Descubre nuestras Categorías</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>
                <div class="col-md-1 text-center">
                    <img src="@/assets/images/icons/marketing.webp" alt="Marketing Digital" class="img-rounded"
                        width="100px">
                    <p>Marketing Digital</p>
                </div>

            </div>
        </div>
        <div class="mt-4">
            <div class="row ">
                <div class="col-md-12">
                    <h5 class="text-white">Últimos Cursos Lanzados</h5>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                
               


            </div>
        </div>
        <div class="mt-4">
            <div class="row ">
                <div class="col-md-12">
                    <h5 class="text-white">Nuestros Mejores Cursos</h5>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
                <div class="col-md-2 zoom">
                    <img src="@/assets/images/imagen_01.jpg" class="rounded img-fluid" alt="">
                    <div>
                        <p class="mt-2 text-white " style="line-height: 1em;">Curso de Python<br>
                            <span class="text-secondary" style="font-size: 10px;"> Por Carli Code</span>
                        </p>
                        <button class="btn btn-primary  btn-sm"><i class="fas fa-shopping-cart"></i> S/120</button>
                    </div>
                </div>
              </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const store = useStore();

onBeforeMount(() => {
    store.state.hideConfigButton = true;
    store.state.showNavbar = false;
    store.state.showSidenav = true;
    store.state.showFooter = true;
});

onBeforeUnmount(() => {
    store.state.hideConfigButton = false;
    store.state.showNavbar = true;
    store.state.showSidenav = true;
    store.state.showFooter = true;
});


</script>
<script>
import CursoService from "../../services/CursoService";
export default {
    data() {
        return {
            cursos:[],
            cargando:false,
        }
    },
    mounted() {
        this.fetchCursos();     
    },
    methods:{
        async fetchCursos(id) {
        try {
          this.cargando = true;
          const response =
            await CursoService.getCursos(id);
          this.cursos=response.data.data        
        } catch (error) {
          console.error("Error al obtener datos:", error);
        } finally {
          this.cargando = false;
        }
      },
    }
}
</script>

<style scoped>
.carousel {
    margin-top: 20px;
}
.zoom img {
   
    transition: transform 0.3s ease; /* Suaviza la transformación */
  }
  
  .zoom:hover img {
    transform: scale(1.05); /* Aumenta el tamaño al 110% */
  }
  
</style>