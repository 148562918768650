<template>
  <div class="container-fluid" style="background-color: #F7F6FE;">
   
  
 
    <div class="row mt-4">
      <div class="col-12">
        <h6>Mis Cursos</h6>
      </div>
    </div>
  
    <div class="row mt-2">
      <div class="col-md-3" v-for="c in cursos_matriculados" :key="c.id">
        <div class="card" @click="irModulos(c.curso.id)" style=" cursor: pointer;">
          <img :src="getImagenUrl(c.curso.imagen)" class="card-img-top" alt="Curso">
          <div class="card-body">         
            <h6 class="card-title" @click="irModulos(c.curso.id)">{{c.curso.nombre}}<br>
            <span class="" style="font-size: 11px;">{{c.curso.horas}} Horas</span></h6>
          </div>
        </div>
      </div>
    </div>
    <Preloader :visible="cargando" />
  </div>
  </template>
  
  <script>
  import MatriculaService from "@/services/MatriculaService";
  import Preloader from "../components/Preloader.vue";
  export default {
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        cantidad_cursos: "",
        certificados: "",
        cursos_matriculados: [],
        id_cliente: "",
        cargando:false,
      };
    },
    components:{
      Preloader,
    },
    mounted() {
  
      if (this.user) {
        this.obtenerEstadisticas(this.user.id);
      }
    },
    methods: {
     
      irModulos(id){
        this.$router.push({
          name:'Modulos',
          params:{
            id_curso:id,
          }
        })
      },
      getImagenUrl(imagen) {
        // Verifica si existe una imagen, si no, retorna una imagen predeterminada
        if (imagen) {
          return process.env.VUE_APP_API_URL + '/storage/' + imagen; // Ajusta la ruta si es necesario
        } else {
          return require('@/assets/images/fondos/curso-defecto.png'); // Imagen por defecto
        }
      },
      async obtenerEstadisticas(id) {
        try {
          this.cargando = true;
          const responseMatriculas =
            await MatriculaService.getCursosMatriculadosPorUsuario(id);
          this.cantidad_cursos = responseMatriculas.data.data.length
          this.cursos_matriculados=responseMatriculas.data.data
          console.log('aqui'+this.cursos_matriculados)       
  
          this.cursosTomando = responseMatriculas.data.filter(
            (curso) => curso.estado === "en_progreso"
          );
        } catch (error) {
          console.error("Error al obtener estadísticas:", error);
        } finally {
          this.cargando = false;
        }
      },
    },
  };
  </script>
  
  <style lang="">
  </style>
  