<template>
    <img :src="imageUrl" alt="Imagen generada"  :class="imageSize" />
  </template>
  
  <script>
  export default {
    name: "Logos",
    props: {
      text: {
        type: String,
        required: true,
      },
      tamano:{
        type:String,
        required: true,
      }
    },
    computed: {
      imageUrl() {
        if (this.text === "esformacion") {
          return require('@/assets/images/marcas/logo-esformacion.webp');
        } else if (this.text === "masprofesional") {
          return require('@/assets/images/marcas/logo-masprofesional.webp');
        } else if (this.text === "capacitasional") {
          return require('@/assets/images/marcas/logo_capacitasional.webp');
        } else {
          return '';
        }
      },
      imageSize() {      
      return {
        'img-small': this.tamano === 'small',
        'img-medium': this.tamano === 'medium',
        'img-large': this.tamano === 'large',
      };
    },
    },
  };
  </script>
  <style scoped>
.img-small {
  width: 100px; 
}
.img-medium {
  width: 40%; 
}
.img-large {
  width: 100%; 
}
</style>
  