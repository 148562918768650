<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = true;
  store.state.showFooter = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});
</script>
<template>
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-header text-center">             
              <h3>Iniciar Sesión</h3>
              <p>Ingrese Credenciales</p>
            </div>
            <div class="card-body">
              <form @submit.prevent="handleLogin">
                <div class="mb-3">
                  <label for="email" class="form-label"
                    >Correo Electrónico</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="form.email"
                    required
                    placeholder="Ingrese su correo"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Contraseña</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="form.password"
                    required
                    placeholder="Ingrese su contraseña"
                  />
                </div>

                <button type="submit" class="btn btn-primary w-100">
                  Iniciar Sesión
                </button>
                <Preloader :visible="cargando" />
              </form>
              <a @click="RecuperarPassword()">Recuperar Contraseña</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "../../services/AuthService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "Login",
  data() {
    return {
      marca: this.$route.params.marca,
      form: {
        email: "",
        password: "",
      },
      cargando: false,
    };
  },
  methods: {
    async handleLogin() {
      try {
        this.cargando = true;
        const response = await AuthService.login(this.form);
        const user = response.data.user;
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(user));
        this.$store.commit("setUser", user);

        this.$router.push({
          name: "Inicio",
        });
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      } finally {
        this.cargando = false;
      }
    },
    RecuperarPassword() {},
  },
  components: { 
    Preloader,
  },
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 480px) {
  .imagen-pc {
    display: none;
  }
}
</style>
