<template>
  <div class="container-fluid">
    <div class="mt-4">
      <div class="row">
        <div class="col-md-12 card">
          <div class="p-4">
            <span class="bg bg-danger text-white p-2">Categoría</span>
            <div class="row">
              <div class="col-md-6">
                <h4>Nombre de curso</h4>
                <p>Profesor Alejadro Diaz</p>
              </div>
              <div class="col-md-6">
                <img :src="getImagenUrl()" class="card-img-top" alt="Curso">
       
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 class="mt-4">Módulos</h5>
      <table class="table">
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="modulo in modulos" :key="modulo.id" style="background-color: white">
            <td @click="irLeccion(modulo.id, modulo.primera_leccion_id)"  class="p-4" style="cursor: pointer;border-radius:5px; ">
              {{ modulo.nombre }}             
            </td>      
          </tr>
        </tbody>
      </table>
    </div>
    <Preloader :visible="cargando" />
  </div>
</template>

<script>
import CalificacionService from "../../services/CalificacionService";
import CertificadoService from "../../services/CertificadoService";
import ModuloService from "../../services/ModuloService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "Modulo",
  data() {
    return {
      modulos: [],
      id_curso: this.$route.params.id_curso,
      calificaciones: {},
      certificados: {},
      cursoNombre: "",
      id_cliente: "",
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  components: {
    Preloader,
  },
  created() {
    this.fetchModulos(this.id_curso);
  },

  methods: {
    getImagenUrl() {
      // Verifica si existe una imagen, si no, retorna una imagen predeterminada
     /* if (imagen) {
        return process.env.VUE_APP_API_URL + '/storage/' + imagen; // Ajusta la ruta si es necesario
      } else {
        return require('@/assets/images/fondos/curso-defecto.png'); // Imagen por defecto
      }*/
      return require('@/assets/images/fondos/curso-defecto.png');
    },
    async llamarCalificacion(id_modulo) {
      try {
        const response =
          await CalificacionService.getCalificacionporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.calificaciones[id_modulo] = response.data.data[0].calificacion;
      } catch (error) {
        console.error("Error fetching calificación", error);
      }
    },

    async llamarCertificado(id_modulo) {
      try {
        const response =
          await CertificadoService.getCertificadoporModulodeUsuario(
            id_modulo,
            this.user.id
          );
        this.certificados[id_modulo] = response.data.data[0].id;
      } catch (error) {
        console.error("Error fetching calificación", error);
      }
    },

    obtenerCalificacion(id_modulo) {
      return this.calificaciones[id_modulo] || "Sin calificación";
    },
    obtenerCertificacion(id_modulo) {
      return this.certificados[id_modulo] || "Sin calificación";
    },

    async fetchModulos(id_curso) {
      try {
        this.cargando = true;
        const response = await ModuloService.getModulosporCurso(id_curso);
        this.modulos = response.data.data;

        for (const modulo of this.modulos) {
          this.llamarCalificacion(modulo.id);
          this.llamarCertificado(modulo.id);
        }
      } catch (error) {
        console.error("Error fetching Modulos", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los módulos.",
        });
      } finally {
        this.cargando = false;
      }
    },

    irLeccion(id_m, id_l) {
      this.$router.push({
        name: "Leccion",
        params: {
          idcurso: this.id_curso,
          idmodulo: id_m,
          idleccion: id_l,
        },
      });
    },
  },
};
</script>

<style scoped></style>
