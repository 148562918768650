import apiClient from '../axios';

export default {
    getExamenPorModulo(id_modulo) {
        return apiClient.get(`/evaluacion/modulo/${id_modulo}`);
    },
    getPreguntasPorEvaluacion(idevaluacion) {
        return apiClient.get(`/preguntas/evaluacion/${idevaluacion}`);
    }

};