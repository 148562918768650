<template>
  <div class="container-fluid" style="background-color: #f7f6fe">
    <main class="main">
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="col-12">
            <h6>Calendario</h6>
          </div>
        </div>

        <div class="row mt-2">
          <div class="card">
            <FullCalendar :options="calendarOptions">
              <template v-slot:eventContent="arg">
                <div class="p-2">
                  <p style="font-size: 11px; font-weight: bold">
                    {{ arg.timeText }}m<br />
                    {{ arg.event.title }}
                  </p>
                </div>
              </template>
            </FullCalendar>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import SesionService from "../../services/SesionService"; // Ajusta la ruta según tu estructura de proyecto

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),

      loading: false,
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        weekends: false,
        events: [],
      },
    };
  },
  mounted() {
    this.cargarCalendario();
  },
  components: {
    FullCalendar,
  },
  methods: {
    eventClick(args) {
      window.open(args.event.url, "_blank");
    },
    cargarCalendario() {
      this.loading = true;

      SesionService.getObtenerSesionesPorUsuario(this.user.id)
        .then((response) => {
          const arrayEvents = response.data.data;
          let arr2 = this.calendarOptions.events;

          arrayEvents.forEach((e) => {
            arr2.push({
              title: e.nombre,
              date: e.fecha + " " + e.hora_inicio,
              url: e.enlace,
            });
          });

          this.loading = false;
        })
        .catch((error) => {
          console.error("Error al obtener sesiones:", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
