<template>
    <div>
        <div class="row text-white">
            <div class="col-md-6">
                <h1 class="text-white">Curso de Git y GitHub</h1>
                <p>Aprende sobre control de versiones y colaboración en proyectos de software. Gestiona repositorios,
                    ramas y conflictos con Git, y utiliza GitHub para alojar proyectos, manejar issues y realizar pull
                    requests. Al finalizar, estarás listo para trabajar en equipo y contribuir a proyectos reales,
                    mejorando tu perfil profesional en desarrollo de software.</p>
                <button class="btn btn-success">Empieza Ya</button>
            </div>
            <div class="col-md-6">
                <img src="@/assets/images/60294.jpg" class="img-fluid rounded" alt="" srcset="">
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>